<template>
  <div>
    <div v-if="slope" :class="cssTrend(slope)">
      <table><tr><td>
        <svg class="me-2" :width="iconSize" :height="iconSize"><use :xlink:href="svgTrend(slope)"/></svg>
      </td><td>
        {{ meaning1 }}
        <div v-if="meaning2">({{meaning2}})</div>
      </td></tr>
      </table>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'TrendComponent',
  components: {},
  props: {
    slope: null,
    hasEnoughData: {type: Boolean, default: true},
    iconSize: {type: Number, default: 64}
  },
  data: function (){
    return {
    }
  },
  // mounted: function() {
  //   return;
  // },
  computed: {
    meaning1: function(){
      if (! this.hasEnoughData){
        return this.$t('trends.slope.Uncertain');
      }
      if (! this.slope) return;
      return this.$t('trends.slope.' + this.slope.meaning)
    },
    meaning2: function(){
      if (! this.hasEnoughData){
        return this.$tc("trends.plotError.generic_error");
      }
      if (! this.slope.meaning2) return;
      return this.slope.meaning2;
    },
  },
  methods: {
    cssTrend: function(slope){
      if (! slope) return;
      const meaning = slope.meaning.toLowerCase();
      const colorByMeaning = this.colorByMeaning(meaning);
      const css = {};

      if (meaning.startsWith("uncertain")){
        css['alert'] = true;
        css['alert-danger'] = true;
      }
      else{
        css['text-' + colorByMeaning] = true;
      }
      return css;
    },

    colorByMeaning(meaning){
      if (! this.hasEnoughData){
        return "secondary";
      }
      if (meaning.startsWith('strong increase')){
        return "primary";
      }
      if (meaning.startsWith('moderate increase')){
        return "primary";
      }
      if (meaning.startsWith('stable')){
        return "secondary";
      }
      if (meaning.startsWith('moderate decrease')){
        return "danger";
      }
      if (meaning.startsWith('strong decrease')){
        return "danger";
      }
      return "secondary";
    },

    svgTrend: function(slope){
      if (! this.hasEnoughData){
        return "#question-circle";
      }
      if (! slope) return;
      const meaning = slope.meaning.toLowerCase();
      if (meaning.startsWith('strong increase')){
        return "#arrow-up-circle";
      }
      if (meaning.startsWith('moderate increase')){
        return "#arrow-up-right-circle";
      }
      if (meaning.startsWith('stable')){
        return "#arrow-right-circle";
      }
      if (meaning.startsWith('moderate decrease')){
        return "#arrow-down-right-circle";
      }
      if (meaning.startsWith('strong decrease')){
        return "#arrow-down-circle";
      }
      return "#question-circle";
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
