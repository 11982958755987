<template>
  <div v-if="plotError" class="alert alert-danger">
    <div v-if="user && user.isAdmin">
      <h6>Error (for Admins only):</h6>
      {{plotError.message}}
    </div>
    <div v-else>
      <h6>{{ $t('trends.plotError.generic_error') }}</h6>
      {{ $t('trends.plotError.generic_error_text') }}
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'PlotErrorSmall',
  components: {},
  props: {
    plotError: Object
  },
  // data: function (){
  //   return {
  //   }
  // },
  // mounted: function() {
  //   return;
  // },
  // methods: {
  //   hello: function () {
  //     return;
  //   },
  // },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
