<template>
  <div>
    <picture>
      <source :srcset="getSpeciesImage(img, 'avif')" type="image/avif">
      <source :srcset="getSpeciesImage(img, 'webp')" type="image/webp">
      <source :srcset="getSpeciesImage(img, 'jpg')" type="image/jpeg">
      <img :src="getSpeciesImage(img, 'jpg')" :class="cssClassesImage">
    </picture>
    <div v-if="bottomBar === true" class="bg-dark text-light rounded-bottom text-center" :class="bottomBarClass" v-html="bottomText">

    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SpeciesImage',
  components: {},
  props: {
    img: Object,
    bottomBar: {type: Boolean, default: true},
    bottomBarClass: {type: String, default: ''},
    bottomBarText: {type: String, default: null},
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    getSpeciesImage: function(imgData, extension){
      return '/static/bats/species/' + imgData.p + '.' + extension;
    },
  },
  computed: {
    cssClassesImage: function(){
      return {
        'd-block': true,
        'rounded-top': this.bottomBar,
        'rounded': !this.bottomBar,
        'w-100': true,
      }
    },
    bottomText: function (){
      if (this.bottomBarText) return this.bottomBarText;
      else if (this.img.c) return '© ' + this.img.c;
      else return 'Unknown photographer';
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>