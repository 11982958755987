<template>
  <div>
    <div :class="cssClassOuter">
      <div v-if="useHeaderDiv === true" :class="cssClassHeader">{{title}}</div>
      <div :class="cssClassBody">
        <h3 v-if="!useHeaderDiv && cardStyle==='borderless'">{{title}}</h3>
        <h5 v-else :class="cssClassHeader">{{title}}</h5>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'MyTrendCard',
  components: {},
  props: {
    title: String,
    cardStyle: {type: String, default: "card"}
  },
  data: function (){
    return {
      useHeaderDiv: false,
      cssClassOuter: "card",
      cssClassBody: "card-body",
      cssClassHeader: "card-title"
    }
  },
  created() {
    if (this.cardStyle === "borderless") {
      this.cssClassOuter = "";
      this.cssClassBody = "";
      this.cssClassHeader = "fs-3 fw-bold";
    }
    else if (this.cardStyle === "card2"){
      this.useHeaderDiv = true;
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    hello: function () {
      return;
    },
  },
  computed: {


  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
