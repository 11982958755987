<template>
  <MyTrendCard :title="$t('trends.populationTrend')">
    <PlotContent :plot="plot" :plot-error="plotError" :plotId="plotId" :working="working"></PlotContent>
  </MyTrendCard>
</template>

<script>
import {defineComponent} from 'vue';
import PlotContent from "@/components/trends/PlotContent.vue";
import MyTrendCard from "@/components/trends/cards/MyTrendCard.vue";

export default defineComponent({
  name: 'PlotCard',
  components: {MyTrendCard, PlotContent},
  props: {
    plot: null,
    plotId: {type: String, default: "divPlot1"},
    plotError: Object,
    working: {type: Boolean, default: false},
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    hello: function () {
      return;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
