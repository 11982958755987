<template>
  <MyTrendCard :title="$t('trends.map')">
    <div id="box2" v-if="!store.plotSettings?.species">
      <div class="w-100 h-100 d-flex flex-row align-items-center justify-content-center my-overlay">
        <div align="center">
          <svg width="128" height="128" class="flex-shrink-0 text-muted"><use xlink:href="#map1"/></svg>
          <p class="lead mt-5">{{ $t('trends.intro.map1' )}}</p>
        </div>
      </div>
    </div>
    <div v-show="store.plotSettings?.species" :id="idMap" style="height: 45vh;"></div>
  </MyTrendCard>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";
import MyTrendCard from "@/components/trends/cards/MyTrendCard.vue";

export default defineComponent({
  extends: MyBase,
  name: 'TcMap',
  components: {MyTrendCard},
  props: {
    idMap: String
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    hello: function () {
      return;
    },
  },
});
</script>

<style>
.map-info {
  padding: 5px;
  background-color: white;
}
</style>
