import {nextTick} from 'vue'
import {createI18n, I18n} from 'vue-i18n'

// export const SUPPORT_LOCALES = ['en', 'ja']

export function setupI18n(d: any) {
    const html = document.querySelector('html');
    let lang = 'en';
    if (html){
        lang = html.getAttribute('lang') || 'en';
    }

    if (d){
        const messages: Record<string, any> = {};
        messages[lang] = d;
        const options = {
            locale: lang,
            messages
        };
        return createI18n(options);
    }

    const options = {
        locale: lang
    };
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: string) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
    } else {
        // i18n.global.locale.value = locale
    }
    // /**
    //  * NOTE:
    //  * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
    //  * The following is an example for axios.
    //  *
    //  * axios.defaults.headers.common['Accept-Language'] = locale
    //  */
    // const html = document.querySelector('html');
    // if (html)
    //     html.setAttribute('lang', locale)
    //
    // {
    //     // loadLocaleMessages(i18n, locale);
    // }
    loadLocaleMessages(i18n, locale);
}

export async function loadLocaleMessages(i18n: any, locale: string) {
    // load locale messages with dynamic import
    const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
        )

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)

    return nextTick()
}