<template>
  <div>
    <table class="table">
      <tbody>
      <tr :class="{'bg-danger': overall.slope.meaning === 'Uncertain'}"><td>Trend</td><td>{{overall.slope.meaning}}</td></tr>
      <tr><td>p</td><td>{{overall.slope.p}}</td></tr>
      <tr><td>add</td><td>{{overall.slope.add.toFixed(8)}} ({{overall.slope.se_add.toFixed(8)}})</td></tr>
      <tr><td>mul</td><td>{{overall.slope.mul.toFixed(8)}} ({{overall.slope.se_mul.toFixed(8)}})</td></tr>

      <tr :class="{'bg-danger': summary.convergence.startsWith('No convergence')}"><td>Convergence</td><td>{{summary.convergence}}</td></tr>
      <tr v-if="summary.overdispersion"><td>overdispersion</td><td>{{summary.overdispersion.toFixed(8)}}</td></tr>
      <tr v-if="summary.serialcorrelation"><td>serial correlation</td><td>{{summary.serialcorrelation.toFixed(8)}}</td></tr>
      </tbody>
    </table>

    <h5 class="mt-5">Timings</h5>
    <table class="table">
      <tbody>
      <tr v-for="(m,idx) in summary.timings" :key="idx"><td>{{ m.m }}</td><td>{{m.t.toFixed(3)}} seconds</td></tr>
      </tbody>
    </table>

    <h5 class="mt-5">Goodness Of Fit</h5>
    <table class="table">
      <tbody>
      <tr><td>Chi Square</td><td>{{summary.gof.chi2.chi2.toFixed(2)}}</td><td>{{summary.gof.chi2.df}}</td><td>{{summary.gof.chi2.p}}</td></tr>
      <tr><td>Likelihood Ratio</td><td>{{summary.gof.lr.LR.toFixed(2)}}</td><td>{{summary.gof.lr.df}}</td><td>{{summary.gof.lr.p}}</td></tr>
      <tr><td>AIC</td><td colspan="3">{{summary.gof.aic.toFixed(2)}}</td></tr>
      </tbody>
    </table>
<!--    <h5 class="mt-5">Download Data</h5>-->
<!--    <button class="btn btn-primary" @click="downloadCsv">CSV</button>-->
<!--    <button class="btn btn-primary" @click="downloadCsvDemo">CSV (Demo)</button>-->
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'TrimInfo',
  components: {},
  props: {
    overall: Object,
    summary: Object,
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  // methods: {
  //   downloadCsv: function () {
  //     this.$emit('download', {type: 'csv'});
  //   },
  //   downloadCsvDemo: function () {
  //     this.$emit('download', {type: 'csv', demo: 1});
  //   },
  // },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
