// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Leaflet from "leaflet";

class Geo{
    public static RADIUS_EARTH_KM = 6371;
    public static RADIUS_EARTH_MI = 3958.8;
    public static CENTER_LAT_GERMANY = 51.1638175
    public static deg2rad(deg: number): number {
        return deg * (Math.PI/180);
    }
    public static haversine(lat1: number, lon1: number, lat2:number, lon2:number): number {
        const R = Geo.RADIUS_EARTH_KM;
        const rlat1 = Geo.deg2rad(lat1);
        const rlat2 = Geo.deg2rad(lat2);
        const difflat = rlat2 - rlat1;
        const difflon = Geo.deg2rad(lon2 - lon1);
        return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    }
}
class SquaresHelper {
    centerLat: number;
    squareSizeInKm: number;
    squareSizeLat: number;
    squareSizeLon: number;
    offsetX: number;
    offsetY: number;

    constructor(squareSizeInKm: number) {
        this.centerLat = Geo.CENTER_LAT_GERMANY;
        this.squareSizeInKm = squareSizeInKm;

        //  you can calculate the distance for the latitude right away.
        const dstY = Geo.haversine(this.centerLat, 0.0, this.centerLat + 0.1, 0.0)
        this.squareSizeLat = (this.squareSizeInKm / dstY) * 0.1
        //  actually you need to calculate the square size of the longitude individually, but we simplify it with squares
        const dstX = Geo.haversine(this.centerLat, 0.0, this.centerLat, 0.1);
        this.squareSizeLon = (this.squareSizeInKm / dstX) * 0.1

        const nbLon = Math.ceil(180.0 / dstX)
        const nbLat = Math.ceil(90.0 / dstY)
        this.offsetX = nbLon * this.squareSizeLon;
        this.offsetY = nbLat * this.squareSizeLat;
        // this.offsetY = 0.0;
        // this.offsetX = 0.0;
    }
    coordsByIndex(indexLat: number, indexLon: number): number[]{
        const lat = indexLat * this.squareSizeLat - this.offsetY;
        const lon = indexLon * this.squareSizeLon - this.offsetX;
        return [lat, lon];
    }
}
class MyMap {
    /** the helper that handles resolving indices to actual GPS coordinates */
    squaresHelper: SquaresHelper;
    /** the Vue component */
    component: any;
    /** the id of the map element */
    idMap: string;
    /** the Leaflet map */
    map: any;
    /** the layer holding the distribution of the bats */
    distributionLayer: any;
    /** the layer holding the observations of the bats */
    squaresLayer: any;
    /** the legend */
    legendDiv: any;
    /** the tile layer holding the map tiles*/
    layer: any;
    tmpLayer: string;

    /** the button control holds the drag and select button */
    dragAndSelectButtonContainer: any;
    infoControl: any;
    infoDiv: any;

    dragAndSelectButton: any;
    dragAndSelect = false;
    dragAndSelectStarted = false;
    dragAndSelectStartPos: Leaflet.LatLng = Leaflet.latLng(0,0);
    /** the selection rectangle (for drag&select) */
    selectRectangle: Leaflet.Rectangle;
    /** used to darken the world outside the selected area */
    outerWorldBounds: Leaflet.Polygon;

    callbackSelectRectangle: any;

    /** for some reason, i need to use the method this way to work. can't define it the regular way */
    dnsDown = (e: Leaflet.LeafletMouseEvent) => {
        if (this.dragAndSelect) {
            // selectRectangle.setBounds([e.latlng, e.latlng]);
            this.dragAndSelectStarted = true;
            this.dragAndSelectStartPos = e.latlng;
            this.selectRectangle.setBounds([e.latlng, e.latlng]);
        }
    };
    dnsUp = (e: Leaflet.LeafletMouseEvent) => {
        if (this.dragAndSelect){
            if (this.dragAndSelectStarted) {
                this.updateSelectRectangle(e.latlng);
                const bounds = this.selectRectangle.getBounds();
                this.dragAndSelectStarted = false;
                this.dragAndSelect = false;
                this.createOuterWorldBounds();
                this.removeDragAndSelectRect();

                const div: HTMLElement = Leaflet.DomUtil.create('div');

                div.innerHTML += '<svg class="me-2" width="32" height="32"><use xlink:href="#bi-bounding-box"/></svg>';
                const button: HTMLAnchorElement = Leaflet.DomUtil.create("a", "fs-6 me-2", div);
                button.innerText = this.component.$t('trends.clearSelection');
                button.href = "#";
                Leaflet.DomEvent.on(button, "click", (e: Leaflet.DomEvent.Event) => {
                    Leaflet.DomEvent.stopPropagation(e);
                    Leaflet.DomEvent.preventDefault(e);
                    this.deleteSelection();
                    this.centerMap();
                });

                const button2: HTMLAnchorElement = Leaflet.DomUtil.create("a", "fs-6", div);
                button2.innerText = this.component.$t('trends.zoomInArea');
                button2.href = "#";
                Leaflet.DomEvent.on(button2, "click", (e: Leaflet.DomEvent.Event) => {
                    Leaflet.DomEvent.stopPropagation(e);
                    Leaflet.DomEvent.preventDefault(e);
                    this.map.fitBounds(bounds);
                });

                this.showInfo(div);
                // this.map.fitBounds(this.selectRectangle.getBounds());
                if (this.callbackSelectRectangle){
                    this.callbackSelectRectangle(bounds);
                }
            }
            this.setDragAndSelect(false);
        }
    }
    dnsMove = (e: Leaflet.LeafletMouseEvent) => {
        if (this.dragAndSelect && this.dragAndSelectStarted) {
            this.updateSelectRectangle(e.latlng);
            e.originalEvent.preventDefault();
        }
    }

    constructor(component: any){
        this.idMap = component.idMap;
        this.component = component;
        this.tmpLayer = this.settings.mapLayer;
        this.squaresHelper = new SquaresHelper(this.settings.squareSize);
    }

    create(){
        if (this.map){
            return;
        }
        const options = {
            center: [51.5, 10],
            zoom: 5.0,
            zoomSnap: 0.25,
            // minZoom: 4.0,
            // maxZoom: 7.0,
            // zoomControl: false
        };
        if (! document.getElementById(this.idMap)){
            console.warn("Oh no! No map element found!");
            return;
        }
        const map = new Leaflet.map(this.idMap, options);



        this.map = map;
        this.createTileLayer();
        this.createInfoControl();
        this.createLegend();
        this.createDragAndSelect();
        // this.centerMap();
    }

    updateUI(){
        if (this.component?.store?.plotSettings?.plotMode === 'CLASSIC') {
            this.createDragAndSelect();
            // this.createDragAndSelectRect();
        }
        else{
            this.removeDragAndSelectButton();
            this.deleteSelection();
            // this.removeDragAndSelectRect();
        }
    }

    centerMap(){
        if (! this.map) return;
        const corner1 = Leaflet.latLng(47.3, 5.8),
            corner2 = Leaflet.latLng(55.1, 15.1),
            bounds = Leaflet.latLngBounds(corner1, corner2);
        this.map.fitBounds(bounds);
    }

    get settings(){
        return this.component.$refs.settings.settings;
    }

    createTileLayer(){
        let url, attribution;
        if (this.settings.mapLayer === 'ArcGIS'){
            url = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";
            attribution = 'Sources: Esri, HERE, Garmin, © OpenStreetMap contributors, and the GIS User Community';
        }
        else{
            url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
            attribution = '© OpenStreetMap contributors';
        }
        if (this.layer) {
            if (this.tmpLayer == this.settings.mapLayer) {
                // console.log("No need to change the layer");
                return;
            }
            this.layer.setUrl(url);
            this.layer.attribution = attribution;
            this.map.zoomOut();
            this.tmpLayer = this.settings.mapLayer;
            return;
        }
        let layer;
        if (this.settings.mapLayer === 'ArcGIS'){
            layer = new Leaflet.TileLayer(url, {attribution: attribution});
        }
        else{
            layer = new Leaflet.TileLayer(url, {attribution: attribution});
        }
        this.layer = layer;
        this.tmpLayer = this.settings.mapLayer;
        this.map.addLayer(this.layer);
    }

    createInfoControl(){
        const ctl = Leaflet.control({position: 'bottomleft'});
        ctl.onAdd = (map: any) => {
            ctl._addedToMap = true;
            // const div: HTMLElement = Leaflet.DomUtil.create('div', 'leaflet-bar leaflet-control map-info fs-5');
            const div: HTMLElement = Leaflet.DomUtil.create('div', 'map-info fs-5');
            this.infoDiv = div;
            return div;
        };
        ctl.onRemove = (map: any) => {
            ctl._addedToMap = false;
        };
        this.infoControl = ctl;
        this.infoControl.addTo(this.map);
    }

    showInfo(content: any){
        if (! this.infoControl) return;
        this.infoDiv.innerHTML = '';
        if (! this.infoControl._addedToMap)
            this.infoControl.addTo(this.map);
        if (typeof content === 'string' && content.length > 0)
            this.infoDiv.innerHTML = content;
        else if (content instanceof HTMLElement){
            this.infoDiv.appendChild(content);
        }
    }
    hideInfo(){
        if (! this.infoControl) return;
        if (this.infoControl._addedToMap)
            this.infoControl.remove();
    }

    createLegend(){
        const labels: any = [];//['<strong>Legend</strong>'];
        labels.push('<em class="badge w-100 fs-6 mb-1" style="background-color: ' + this.settings.mapOptionsDistribution.color + '; border:1px solid black;">' + this.component.$t('trends.distribution') + '</em>')
        labels.push('<em class="badge w-100 fs-6" style="background-color: ' + this.settings.mapOptionsSite.color + '; border:1px solid black;">' + this.component.$t('trends.observations') + '</em>')


        if (this.legendDiv) {

            this.legendDiv.innerHTML = labels.join('<br>');
            return;
        }
        const legend = Leaflet.control({position: 'topright'});
        legend.onAdd = (map: any) => {//function (map) {
            const div = Leaflet.DomUtil.create('div', 'map-legend');
            div.innerHTML = labels.join('<br>');
            this.legendDiv = div;
            return div;
        };
        legend.addTo(this.map);
    }

    createDragAndSelectRect(){
        // if (this.selectRectangle){
        //     this.selectRectangle.remove();
        // }
        this.removeDragAndSelectRect();
        this.selectRectangle = Leaflet.rectangle([[0,0], [0,0]], { color: "#000", weight: 2, fillColor: "#00f", fillOpacity: 0.25 });
        this.selectRectangle.addTo(this.map);
    }
    removeDragAndSelectRect(){
        if (this.selectRectangle){
            this.selectRectangle.remove();
            this.selectRectangle = undefined;
        }
    }

    removeDragAndSelectButton(){
        if (!this.dragAndSelectButtonContainer) return;
        //  remove the info panel (rect)
        this.dragAndSelectButtonContainer.remove();
        this.dragAndSelectButtonContainer = undefined;
        //  remove the element representing the select rectangle
        this.removeDragAndSelectRect();
        //  remove the drag and select button
        this.map.off("mousedown", this.dnsDown);
        this.map.off("mouseup", this.dnsUp);
        this.map.off("mousemove", this.dnsMove);
    }
    createDragAndSelect(){
        if (this.dragAndSelectButtonContainer) return;
        const ctl = Leaflet.control({position: 'topleft'});
        this.dragAndSelectButtonContainer = ctl;
        ctl.onAdd = (map: any) => {//function (map) {
            const div: HTMLElement = Leaflet.DomUtil.create('div', 'leaflet-bar leaflet-control');
            const button: HTMLAnchorElement = Leaflet.DomUtil.create("a", "leaflet-control-custom", div);
            button.href = "#";
            button.title = this.component.$t('trends.map.dragAndSelect');
            button.innerHTML = '<svg class="bi" width="24" height="24"><use xlink:href="#bi-bounding-box"/></svg>';
            this.dragAndSelectButton = button;

            Leaflet.DomEvent.on(button, "click", (e: Leaflet.DomEvent.Event) => {
                Leaflet.DomEvent.stopPropagation(e);
                Leaflet.DomEvent.preventDefault(e);
                this.setDragAndSelect(! this.dragAndSelect);
            });

            return div;
        };
        ctl.addTo(this.map);


        // Handle map click event to update the drag-and-select rectangle
        this.map.on("mousedown", this.dnsDown);
        this.map.on("mouseup", this.dnsUp);
        // Handle map mousemove event to update the drag-and-select rectangle during dragging
        this.map.on("mousemove", this.dnsMove);

        // this.map.on("touchstart", (e: Leaflet.LeafletMouseEvent) => {
        //     this.onDown(e);
        // });
        // this.map.on("touchend", (e: Leaflet.LeafletMouseEvent) => {
        //     this.onUp(e);
        // });
        // this.map.on("touchcancel", (e: Leaflet.LeafletMouseEvent) => {
        //     this.onUp(e);
        // });
        // this.map.on("touchmove", (e: Leaflet.LeafletMouseEvent) => {
        //     this.onMove(e);
        // });
    }

    deleteSelection(){
        this.removeOuterWorldBounds();
        this.removeDragAndSelectRect();
        this.component.$nextTick(() => {
            this.hideInfo()
            if (this.callbackSelectRectangle){
                this.callbackSelectRectangle(null);
            }
        });
        // setTimeout(() => {
        //     this.hideInfo()
        //     if (this.callbackSelectRectangle){
        //         this.callbackSelectRectangle(null);
        //     }
        // }, 10);
    }

    setDragAndSelect(enabled: boolean){
        this.dragAndSelect = enabled;
        this.dragAndSelectStarted = false;
        if (this.dragAndSelect){
            this.createDragAndSelectRect();
            let html = "";
            html += '<svg class="bi me-2" width="32" height="32"><use xlink:href="#bi-bounding-box"/></svg>';
            html += this.component.$t('trends.dragAndSelect');
            this.showInfo(html);
            this.map.dragging.disable();
            this.dragAndSelectButton.style.backgroundColor = "black";
            this.dragAndSelectButton.style.color = "white";
        }
        else{
            // this.hideInfo();
            this.map.dragging.enable();
            this.dragAndSelectButton.style.backgroundColor = "white";
            this.dragAndSelectButton.style.color = "black";
        }
    }

    updateSelectRectangle(latlng: Leaflet.LatLng){
        const minLat = Math.min(this.dragAndSelectStartPos.lat, latlng.lat);
        const maxLat = Math.max(this.dragAndSelectStartPos.lat, latlng.lat);
        const minLng = Math.min(this.dragAndSelectStartPos.lng, latlng.lng);
        const maxLng = Math.max(this.dragAndSelectStartPos.lng, latlng.lng);
        this.selectRectangle.setBounds([[minLat, minLng], [maxLat, maxLng]]);
    }

    createOuterWorldBounds(){
        this.removeOuterWorldBounds();
        const outerBounds = [
            [-90, -1800],
            [-90, 1800],
            [90, 1800],
            [90, -1800],
        ];
        const rectBounds = this.selectRectangle.getBounds();
        const innerBounds = [
            [rectBounds.getSouth(), rectBounds.getWest()],
            [rectBounds.getSouth(), rectBounds.getEast()],
            [rectBounds.getNorth(), rectBounds.getEast()],
            [rectBounds.getNorth(), rectBounds.getWest()],
        ];

        this.outerWorldBounds = Leaflet.polygon([outerBounds, innerBounds], { color: '#000', opacity: 1.0, fillOpacity: 0.5 }).addTo(this.map);
    }

    removeOuterWorldBounds(){
        if (this.outerWorldBounds){
            this.outerWorldBounds.remove(this.map);
        }
        this.outerWorldBounds = undefined;
    }

    createDistributionLayer(distributionSquares: any[]){
        if (!this.map) return;
        // if (this.map){
        //     this.map.off();
        //     this.map.remove();
        //     this.create();
        // }
        this.createTileLayer();
        this.createLegend(); //this is a quick hack to make the legend update (colors change)
        const settings = this.settings;
        if (this.distributionLayer) {
            this.map.removeLayer(this.distributionLayer);
        }
        if (!settings.showDistribution) return;
        const allSquares = [];
        const options: any = JSON.parse(JSON.stringify(settings.mapOptionsDistribution));
        for (const idx in distributionSquares) {
            const s = distributionSquares[idx];

            const poly = this.polygonFromSquare(s, options);
            if (! poly) continue;
            allSquares.push(poly);
        }
        const distributionLayer = new Leaflet.LayerGroup(allSquares);
        distributionLayer.addTo(this.map);
        this.distributionLayer = distributionLayer;
    }

    createSitesLayer(squares: any[]){
        const settings = this.settings;
        if (this.squaresLayer) {
            this.map.removeLayer(this.squaresLayer);
        }

        if (!settings.showSquares) return;
        const allSquares = [];
        const useCircles = settings.mapSiteShape === 'circle';
        let poly;
        const options: any = JSON.parse(JSON.stringify(settings.mapOptionsSite));
        for (const idx in squares) {
            const s = squares[idx];
            if (useCircles) {
                alert("TODO: implement circle")
                // const min = s.polygon[0];
                // const max = s.polygon[2];
                // const cx = 0.5* (max[1] - min[1]) + min[1];
                // const cy = 0.5* (max[0] - min[0]) + min[0];
                // options.radius = 9000;
                // poly = Leaflet.circle([cy, cx], options);
            }
            const poly = this.polygonFromSquare(s, options);
            if (! poly) continue;
            let popupHtml = '<h6>' + this.component.$tc('trends.sites', {n: s.nb_loc}) + '</h6><div class="text-muted">';
            if (s.contributors) {
                // popupHtml += 'Contributors: ';
                const contributors = [];
                for (const cIdx in s.contributors) {
                    let c = undefined;
                    for (const cmp of this.component?.contributors || []){
                        if (cmp.id == cIdx) c = cmp;
                    }
                    if (c){
                        contributors.push(c.name);
                    }
                    else{
                        contributors.push('Unknown contributor with id ' + cIdx + ' (please report this bug)');
                    }
                }
                popupHtml += contributors.join(', ');
            }
            else{
                popupHtml += 'No contributors';
            }
            popupHtml += '</div>';
            poly.bindPopup(popupHtml);
            allSquares.push(poly);
        }
        const squaresLayer = new Leaflet.LayerGroup(allSquares);
        squaresLayer.addTo(this.map);
        this.squaresLayer = squaresLayer;
    }

    polygonFromSquare(s: any, options: any){
        if (s.polygon) {
            return Leaflet.polygon(s.polygon, options);
        }
        else if (s.i){
            const [lat1, lon1] = this.squaresHelper.coordsByIndex(s.i[0], s.i[1]);
            let lat2, lon2;
            if (s.i2){
                [lat2, lon2] = this.squaresHelper.coordsByIndex(s.i2[0] + 1, s.i2[1] + 1);
            }
            else{
                [lat2, lon2] = this.squaresHelper.coordsByIndex(s.i[0] + 1, s.i[1] + 1);
            }

            const poly = [
                [lat1, lon1],
                [lat1, lon2],
                [lat2, lon2],
                [lat2, lon1]
            ];
            return Leaflet.polygon(poly, options);
        }
        return undefined;
    }
}

export {MyMap}