<template>
  <div>
    <div v-if="! hasEnoughData" :class="cssTrend(undefined)" class="d-flex align-items-center">
      <svg class="me-2" :width="iconSizeLarge" :height="iconSizeLarge"><use xlink:href="#question-circle"/></svg>
      <div>{{ $t('trends.slope.Uncertain') }}<br>{{ $t('trends.plotError.generic_error') }}</div>
    </div>
<!--    <div v-else-if="significantSegments.length <= 1" class="d-flex align-items-center" :class="cssTrend(undefined)">-->
<!--      <svg class="me-2" :width="iconSizeLarge" :height="iconSizeLarge"><use xlink:href="#arrow-right-circle"/></svg>-->
<!--      <div>{{ $t('trends.slope.Stable') }}</div>asasdasdasd-->
<!--    </div>-->
    <div v-else>
      <div v-for="(seg, key) in significantSegments" :key="key" class="d-flex align-items-center" :class="cssTrend(seg)">
        <svg class="me-2" :width="iconSize" :height="iconSize"><use :xlink:href="svgTrend(seg)"/></svg>
        <div>{{ timespanText(seg) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {MyD3} from "@/MyD3";

class SignificantSegment {
  yearStart = 0;
  yearEnd = 0;
  significance = 0;
  constructor(trace, segment){
    this.yearStart = Math.round(trace.items[segment.x0]?.data?.year);
    this.yearEnd = Math.round(trace.items[segment.x1]?.data?.year || trace.items[trace.items.length - 1]?.data?.year);
    this.significance = segment.significance;
  }
}
export default defineComponent({
  name: 'TrendComponentGAM',
  components: {},
  props: {
    plot: null,
    hasEnoughData: {type: Boolean, default: true},
    iconSizeLarge: {type: Number, default: 64},
    iconSize: {type: Number, default: 16}
  },
  data: function (){
    return {
      pp: null,
      significantSegments: [],
      displayInsignificants: true,
    }
  },
  mounted: function() {
    if (this.plot){
      this.updateFromPlot();
    }

  },
  watch: {
    plot: function(){
      this.updateFromPlot();
    }
  },
  methods: {
    updateFromPlot: function(){
      this.pp = MyD3.getPreparedPlot(this.plot);
      //  find the trace with a significance
      let significantTrace = undefined;
      for (const trace of this.pp.traces){
        for (const itm of trace.items){
          if (itm.data?.significance !== undefined){
            significantTrace = trace;
            break;
          }
        }
        if (significantTrace) break;
      }

      const sigsegs = [];
      if (significantTrace){
        const segments = MyD3.createSegmentsBySignificance(significantTrace);
        // now find all the significant segments
        for (const seg of segments){
          if (this.displayInsignificants || seg.significance > 0){
            sigsegs.push(new SignificantSegment(significantTrace, seg));
          }
        }
      }
      this.significantSegments = sigsegs;
    },
    cssTrend: function(seg){
      const css = {};
      if (! this.hasEnoughData){
        // css['alert'] = true;
        css['text-danger'] = true;
      }
      else{
        const color = this.colorBySeg(seg);
        css['text-' + color] = true;
      }
      return css;
    },

    colorBySeg(seg){
      if (! this.hasEnoughData || !seg){
        return "secondary";
      }
      if (seg.significance === 1){
        return "primary";
      }
      else if (seg.significance === 2){
        return "danger";
      }
      else{
        return "secondary";
      }
    },

    svgTrend: function(seg){
      if (! this.hasEnoughData){
        return "#question-circle";
      }
      if (seg.significance === 1){
        return "#arrow-up-right-circle";
      }
      else if (seg.significance === 2){
        return "#arrow-down-right-circle";
      }
      else{
        return "#arrow-right-circle";
      }
    },

    timespanText: function (seg){
      if (seg.yearStart === seg.yearEnd) return `${seg.yearStart}`;
      return `${seg.yearStart} - ${seg.yearEnd}`;
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
