<template>
  <div :id="carouselId" class="carousel slide" data-bs-interval="false">
    <div v-if="indicators === true" class="carousel-indicators">
      <button v-for="(img, idx) in images" :class="{'active': idx == 0}" :key="'b'+species.id+'-'+idx"
              type="button" :data-bs-target="'#' + carouselId" :data-bs-slide-to="idx">
      </button>
    </div>
    <div class="carousel-inner">
      <div v-for="(img, idx) in images" class="carousel-item" :class="{'active': idx == 0}" :key="'i'+species.id+'-'+idx" data-bs-interval="false">
        <SpeciesImage :img="img" :bottom-bar="bottomBar" bottomBarClass="pb-5"></SpeciesImage>
      </div>
    </div>
    <button v-if="images.length>1" class="carousel-control-prev" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button v-if="images.length>1" class="carousel-control-next" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import SpeciesImage from "@/components/trends/cards/SpeciesImage.vue";

export default defineComponent({
  name: 'SpeciesImageCarousel',
  components: {SpeciesImage},
  props: {
    species: Object,
    images: Object,
    bottomBar: {type: Boolean, default: true},
    indicators: {type: Boolean, default: true},
    mkey: {type: String, default: ''}
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
  },
  computed: {
    carouselId: function (){
      return 'speciesCarousel' + this.mkey;
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
