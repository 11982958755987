<template>
  <div>
    <h5 class="mt-5">Download Data</h5>
    <button class="btn btn-primary" @click="downloadCsv">CSV</button>
    <!--    <button class="btn btn-primary" @click="downloadCsvDemo">CSV (Demo)</button>-->
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'DownloadData',
  components: {},
  props: {
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    downloadCsv: function () {
      this.$emit('download', {type: 'csv'});
    },
    downloadCsvDemo: function () {
      this.$emit('download', {type: 'csv', demo: 1});
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
