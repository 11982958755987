import axios, {AxiosResponse} from "axios";

class ApiCommunication{
    url: string;
    constructor(url: string) {
        this.url = url;
        axios.defaults.xsrfCookieName = 'csrftoken';
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    }
    handleFatalError(app: any, response:AxiosResponse) {
        if (response.data.fatalError){
            app.working = true;
            app.raiseFatalError("Fatal Error", response.data.fatalError.message, 500);
            return true;
        }
        return false;
    }
    get(app: any, responseHandler: any, errorHandler: any){
        app.working = true;
        axios.get(this.url).then((response:AxiosResponse) => {
            this.handleResponse(app, response, responseHandler, errorHandler);
        }).catch(function(error){
            errorHandler(error);
        });
    }
    post(app: any, data: any, responseHandler: any, errorHandler: any, config: any){
        app.working = true;
        axios.post(this.url, data, config)
            .then((response: AxiosResponse) => {
                this.handleResponse(app, response, responseHandler, errorHandler);
            }).catch(function (error){
                console.log(error);
                errorHandler(error);
            });
    }

    handleResponse(app: any, response: AxiosResponse, responseHandler: any, errorHandler: any){
        if (this.handleFatalError(app, response)){
            return;
        }
        try {
            responseHandler(response.data);
            // app.working = false;
            // app.fatalError = null;
        }
        catch (e){
            console.log(e);
            errorHandler(e);
        }
    }
    postFile(app: any, file: any, responseHandler: any, errorHandler: any){
        const formData = new FormData();
        formData.append("file", file);
        this.postFormData(app, formData, responseHandler, errorHandler);
    }
    postFormData(app: any, formData: FormData, responseHandler: any, errorHandler: any){
        this.post(app, formData, responseHandler, errorHandler, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static postDownload(url: string, data: any, filename: string|undefined = undefined){
        axios.post(url, data, {responseType: 'blob'}).then((response: AxiosResponse) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if (filename) {
                link.setAttribute('download', filename);
            }
            document.body.appendChild(link);
            link.click();
        });
    }
}

class ApiRequestBuilder{
    actions: any[];
    constructor(actionId: string, properties: any) {
        this.actions = [];
        if (actionId){
            this.addAction(actionId, properties);
        }
    }
    addAction(actionId: string, properties: any){
        if (! properties) properties = {};
        properties.action = actionId;
        this.actions.push(properties);
        return this;
    }
    build(){
        return {
            actions: this.actions
        };
    }
}

const ApiUtil = {
    addKeyToMapItemsAsId: function (map: any){
        for (const id in map) {
            map[id].id = id;
        }
        return map;
    },
    listToMapWithIdAsKey: function(l: any){
        const map = {};
        let item;
        for (const idx in l){
            item = l[idx];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            map[item.id] = item;
        }
        return map;
    },
    mapToList: function(map: any){
        const list = [];
        for (const id in map){
            list.push(map[id]);
        }
        return list;
    },
    listFromIds: function (ids: any, map: any){
        const l = [];
        for (const idx in ids){
            const id = ids[idx];
            const o = map[id];
            if (o) l.push(o);
        }
        return l;
    },
    // getSpeciesImage: function(speciesId: string, fallback: boolean){
    //     if (fallback)
    //         return '/static/bats/species/' + speciesId + '.jpg';
    //     return '/static/bats/species/' + speciesId + '.avif';
    // }
};

export {ApiCommunication, ApiRequestBuilder, ApiUtil}