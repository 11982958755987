<template>
  <div :id="plotId" style="height: 45vh;"></div>
  <div v-if="!store.plotSettings?.species" class="w-100 h-100 d-flex flex-row align-items-center justify-content-center my-overlay">
    <div align="center">
      <svg width="128" height="128" class="flex-shrink-0 text-muted"><use xlink:href="#graph1"/></svg>
      <p class="lead mt-5">{{ $t('trends.intro.trend1' )}}<br><a class="text-muted" href="/methodology">{{ $t('trends.intro.trend2' )}}</a></p>
    </div>
  </div>
  <div v-else-if="!plot && !working" class="w-100 h-100 alert alert-danger d-flex flex-row align-items-center justify-content-center my-overlay">
    <svg width="64" height="64" class="flex-shrink-0"><use xlink:href="#exclamation-triangle"/></svg>
    <div class="ps-3">
      <h5>{{ $t('trends.plotError.generic_error') }}</h5>
      <div v-if="plotError && user?.isAdmin">
        [{{plotError.severity}}]:
        {{plotError.message}}
      </div>
      <div v-else>
        {{ $t('trends.plotError.generic_error_plot') }}
      </div>

    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'PlotContent',
  components: {},
  props: {
    plot: null,
    plotId: {type: String, default: "divPlot1"},
    plotError: Object,
    working: {type: Boolean, default: false},
  },
  // data: function (){
  //   return {
  //   }
  // },
  // methods: {
  //   hello: function () {
  //     return;
  //   },
  // },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.my-overlay {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
