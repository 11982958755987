import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from "pinia";
import {setupI18n} from "@/account/i18n";

declare const vueI18n:any;
// alert(vueI18n);

const pinia = createPinia()
const i18n = setupI18n(vueI18n)
const app = createApp(App)
app.use(pinia)
app.use(i18n)

app.mount('#app')