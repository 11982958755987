class User{
    name: string;
    isAdmin: boolean;
    isStaff: boolean;
    permissions: Record<string, any>;
    suppliers: Record<string, any>[];
    public constructor(json: Record<string, any>) {
        this.name = json.name;
        this.isAdmin = json.admin;
        this.isStaff = json.staff;
        this.permissions = {};
        for (let i=0; i < json.permissions.length; i++){
            const permission = json.permissions[i];
            this.permissions[permission] = true;
        }
        this.suppliers = json.suppliers;
    }

    public hasPermission(name: string) : boolean{
        if (this.permissions[name]){
            return true;
        }
        return false;
    }
}



export {User}