<template>
  <div v-if="working" id="indicator" style="position: fixed; top:0; left:0; z-index:1001; width: 100%; height:100%; background: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div v-if="fatalError" class="alert alert-danger">
            <strong>{{fatalError.name}} {{fatalError.status}}</strong>
            <p>{{fatalError.message}}</p>
            <p>{{ $t("spinner.reloadText") }}</p>
            <div>
              <button type="button" class="btn btn-danger btn-lg px-4 gap-3" @click="reloadPage()">{{ $t("spinner.reload") }}</button>
            </div>
          </div>
          <div v-else-if="confirm">
            <h4>{{confirm.title}}</h4>
            <p>{{confirm.text}}</p>
            <div>
              <button type="button" class="btn btn-success btn-lg px-4 mx-3" @click="confirm.fnYes">{{ $t("spinner.yes") }}</button>
              <button type="button" class="btn btn-danger btn-lg px-4 mx-3" @click="confirm.fnNo">{{ $t("spinner.no") }}</button>
            </div>
          </div>
          <div v-else>
            <div class="spinner-border m-5" role="status">
            </div>
            <p>{{ $t("spinner.wait") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'MySpinner',
  props: {
    working: Boolean,
    fatalError: Object,
    confirm: Object,
  },
  data: function (){
    return {

    }
  },
  methods: {
    reloadPage: function(){
      window.location.reload();
    },
  },
});
</script>