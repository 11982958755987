
import {defineComponent} from 'vue';
import PlotsThree from "@/components/trends/Plots3.vue";

export default defineComponent({
  name: 'App',
  components: {
    PlotsThree,
  },
  data: function () {
    return {
      myView: "",
    }
  },
  created() {
    this.myView = document.getElementById("app")?.getAttribute("my_view") || "plot";
  }
});
