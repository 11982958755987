<template>
  <div>
    <h5 class="mt-5">Timings</h5>
    <table class="table">
      <tbody>
      <tr v-for="(m,idx) in timings" :key="idx"><td>{{ m.m }}</td><td>{{m.t.toFixed(3)}} seconds</td></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";

export default defineComponent({
  extends: MyBase,
  name: 'TimingsInfo',
  components: {},
  props: {
    timings: Object
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
