<script>
import {defineComponent} from 'vue';
import {useTrendsStore} from "@/store/trends";

export default defineComponent({
  data: function (){
    return {
      mStore: null,
    }
  },
  // mounted: function() {
  //   return;
  // },
  methods: {
    _getStore() {
      if (!this.mStore){
        this.mStore = useTrendsStore();
      }
      return this.mStore;
    },
  },
  computed: {
    store: function () {
      return this._getStore();
    },
    accountComponent: function () {
      return this.store.accountComponent;
    },
    user: function () {
      return this.store.user;
    },
  }
});
</script>
