<template>
  <MyTrendCard :title="title">
    <div id="box3">
      <div v-if="!store.plotSettings?.species" class="my-overlay col-lg-8 mx-auto">
        <div class="text-center">
          <svg width="128" height="128" class="flex-shrink-0 text-muted"><use xlink:href="#person-heart"/></svg>
          <p class="lead mt-5">{{ $t('trends.intro.contributors1' )}}</p>
          <p class="lead">{{ $t('trends.intro.contributors2' )}}</p>

          <p class="lead"><a href="/accounts/signup">{{ $t('trends.intro.contributorsAccLink') }}</a></p>

          <p class="lead"><a href="mailto:info@batlas.info">info@batlas.info</a></p>

          <p class="lead mt-5"><strong>{{ $t('trends.intro.privacy1' )}}</strong><br>
            {{ $t('trends.intro.privacy1a' )}}
            <a href="/static/bats/misc/BATLAS-Vereinbarung-Datentransfer-und-Datennutzung.docx">{{ $t('trends.intro.privacy1b' )}}</a>
            {{ $t('trends.intro.privacy1c' )}} {{ $t('trends.intro.privacy1d' )}}
          </p>

          <ul class="text-start mt-5 lead">
            <li class="my-2">{{ $t('trends.intro.privacy2a' )}}</li>
            <li class="my-2">{{ $t('trends.intro.privacy2b' )}}</li>
          </ul>

        </div>
      </div>
      <div v-else>
<!--        <PlotErrorSmall :plotError="plotError"></PlotErrorSmall>-->

<!--        <TrendComponent v-if="overall" class="text-start" :slope="overall.slope" :icon-size="64" margin-top="0"></TrendComponent>-->
<!--        <div v-if="nbCounts">-->
<!--          {{$tc('trends.moreCounts1', {n: nbCounts})}}-->
<!--          {{$tc('trends.moreCounts2', {n: contributors.length})}}-->
<!--          {{$tc('trends.moreCounts3', {n: nbLocations})}}-->
<!--        </div>-->

        <div v-if="contributors">
          <ContributorsAccordion :contributors="contributors"></ContributorsAccordion>
        </div>

        <div v-if="user && user.name && user.isAdmin">
          <div v-if="timings">
            <TimingsInfo :timings="timings"></TimingsInfo>
          </div>
          <div v-if="overall">
            <h5 class="mt-5">TRIM</h5>
            <TrimInfo :summary="summary" :overall="overall" @download="download"></TrimInfo>
          </div>
          <DownloadData @download="download"></DownloadData>
        </div>
      </div>
    </div>
  </MyTrendCard>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";
import MyTrendCard from "@/components/trends/cards/MyTrendCard.vue";
import ContributorsAccordion from "@/components/trends/cards/ContributorsAccordion.vue";
import TrimInfo from "@/components/trends/cards/TrimInfo.vue";
import TimingsInfo from "@/components/trends/cards/TimingsInfo.vue";
import DownloadData from "@/components/trends/cards/DownloadData.vue";

export default defineComponent({
  extends: MyBase,
  name: 'TcMore',
  components: {DownloadData, TimingsInfo, TrimInfo, ContributorsAccordion, MyTrendCard},
  props: {
    overall: Object,
    summary: Object,
    timings: Object,
    contributors: Object,
    plotError: Object,
  },
  data: function (){
    return {
    }
  },
  mounted: function() {
    return;
  },
  methods: {
    supplierHasInfo: function (supplier){
      return !!(supplier.url || supplier.desc || supplier.contributors);
    },
    download: function (data){
      this.$emit('download', data);
    },
  },
  computed: {
    title: function(){
      // if (! this.store.plotSettings?.species){
      //
      // }
      if (this.contributors){
        return this.$tc('trends.nbSources', {n: this.contributors.length})
      }
      return this.$t('trends.contribute');
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
