<template>
  <div id="carouselSP" class="carousel carousel-dark slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" v-for="(page, key) in pages" :key="key" :class="{'active': key==0}" data-bs-interval="false">
        <div class="d-flex flex-row justify-content-evenly">
          <div v-for="(spec, key) in page.imgs" :key="key" class="m-2 preview" @click="clickSpecies(spec)">
            <SpeciesImage :img="spec.images[Math.floor(Math.random() * spec.images.length)]" class="mb-3" style="max-width: 224px;" :bottom-bar-text="previewSpeciesHtml(spec)" bottom-bar-class="fs-6"></SpeciesImage>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-indicators">
<!--      <button class="" type="button" data-bs-target="#carouselSP" data-bs-slide="prev"></button>-->
      <button type="button" data-bs-target="#carouselSP" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselSP" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselSP" data-bs-slide-to="2" aria-label="Slide 3"></button>
<!--      <button class="" type="button" data-bs-target="#carouselSP" data-bs-slide="prev"></button>-->
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";
import SpeciesImage from "@/components/trends/cards/SpeciesImage.vue";

export default defineComponent({
  extends: MyBase,
  name: 'SpeciesPreviewCarousel',
  components: {SpeciesImage},
  props: {
    countTypes: Array
  },
  data: function (){
    return {
      pages: [],
      width: 0
    }
  },
  mounted: function() {
    const pages = [];
    const elm = document.getElementById('mainContainer');
    // const countTypes = this.store.plotSettings.availableCountTypes;
    const countTypes = this.countTypes;
    if (elm) {
      const computedStyles = window.getComputedStyle(elm);
      const width = parseFloat(computedStyles.width);
      this.width = width;
      const nbPerRow = Math.floor(width / 224);
      // const nbPerRow = 5;
      // const nbPerRow = this.findNbItemsPerRow(countTypes.length, Math.floor(width / 192));
      const nbRows = Math.ceil(countTypes.length / nbPerRow);
      console.log("mounted", width, nbPerRow, nbRows, countTypes.length);
      for (let i = 0; i < nbRows; i++) {
      // console.log(i, nbRows);
        const imgs = countTypes.slice(i * nbPerRow, (i + 1) * nbPerRow);
        pages.push({
          imgs
        });
      }
    }
    this.pages = pages;
  },
  methods: {
    previewSpeciesHtml: function (spec){
      // return `<em>${spec.latin}</em><div class="fs-6">${this.capitalizeAll(spec.name)}</div>`;
      return `<em>${spec.latin}</em>`;
      // return <em>' + spec.latin + '</em><span' + capitalizeAll(spec.name)
    },
    clickSpecies: function (spec){
      this.$emit('selectSpecies', spec);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.preview {
  cursor: pointer;
}

div.carousel-indicators{
  position: relative;
}

/* Style for each individual indicator */
div.carousel-indicators button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #6c757d; /* Inactive indicator color */
  border: none;
  padding: 0;
  cursor: pointer;
}

/* Active indicator style */
div.carousel-indicators button.active {
  background-color: #007bff; /* Active indicator color */
}
</style>
