<script>
import {defineComponent} from 'vue';
import {ApiCommunication, ApiRequestBuilder} from "@/ApiCom";
import {MyD3, MyPlotVisuals} from "@/MyD3";
import {User} from "@/User";
import MyBase from "@/components/trends/MyBase.vue";
import {MyPlotSettings} from "@/components/trends/settings/MyPlotSettings";
import {MyMap} from "@/trends/map";
import axios from "axios";

const ApiCom = new ApiCommunication('/api_plot');

export default defineComponent({
  extends: MyBase,
  data: function (){
    return {
      prefix: "x", //used for html ids etc.; prevents collisions with other components (for previewing multiple layouts)

      working: true,
      fatalError: null,
      speciesImages: [],

      map: null,
      plotSettings: null,

      //  these are obsolete. actually you should use the plotsettings (below)
      //  TODO: make them computed props
      species: null,
      speciesTitles: null,
      //  these are obsolete. actually you should use the plotsettings (above)

      squares: [],
      locations: [],
      contributors:[],
      nbLocations: 0,
      nbCounts: 0,

      plot: null,
      plotError: null,
      overall: null,
      summary: null,
      timings: null,
    };
  },
  created: function() {
    if (! this.store.plotSettings){
      this.store.plotSettings = new MyPlotSettings(this);
    }
    window.addEventListener("resize", this.resizedListener);
  },
  unmounted: function() {
    window.removeEventListener("resize", this.resizedListener);
  },
  mounted() {
    this.createMap();
    this.resizedListener();
    if (window.trendData){
      this.handleResponse(window.trendData);
    }
    this.speciesImages = this.species?.images || [];
  },
  methods: {
    resizedListener: function(){
      if (this.resizeTimeoutId){
        clearTimeout(this.resizeTimeoutId);
      }
      this.resizeTimeoutId = setTimeout(this.resizedFn, 150);
    },
    resizedFn: function(){
      const elmMap = document.getElementById(this.idMap);
      const elmMap2 = document.getElementById("box2");
      const elmPlot = document.getElementById(this.getPlotId());
      if (elmMap2) {
        const h = Math.max(elmMap2.clientHeight, Math.floor(elmMap2.clientWidth * 0.7));
        elmMap2.style.height = h + 'px';
        elmMap.style.height = h + 'px';
        elmPlot.style.height = h + 'px';
      }
      else{
        const h = Math.max(elmMap.clientHeight, Math.floor(elmMap.clientWidth * 0.7));
        elmMap.style.height = h + 'px';
        elmPlot.style.height = h + 'px';
      }
      this.map.map.invalidateSize();
      this.updatePlot();
    },
    get: function (){
      const data = new ApiRequestBuilder('GET', this.buildOptions()).build();
      ApiCom.post(this, data, this.handleResponse, this.handleError);
    },
    handleResponse: function (json){
      this.working = false;
      this.fatalError = null;
      const settingsComponent = this.$refs.settings;
      const settings = settingsComponent.settings;
      if (json.user){
        this.store.user = new User(json.user);
      }
      settingsComponent.handleResponse(json);
      if (json.species) {
        this.speciesDict = settings.speciesDict;
        this.species = settings.species;

        this.speciesTitles = json.speciesDesc.titles;
        for (const speciesId in this.speciesDict){
          const descId = json.speciesDesc.species[speciesId];
          if (descId){
            const desc = json.speciesDesc.data[descId];
            this.speciesDict[speciesId].desc = desc;
          }
        }
        const images = json.speciesImages;
        for (const speciesId in images){
          const s = this.speciesDict[speciesId];
          s.images = images[speciesId];
        }
      }

      this.speciesDict = settings.speciesDict;
      this.species = settings.species;

      this.store.plotSettings.handleResponse(json);

      this.plot = json.plot;
      this.updatePlot();

      this.nbLocations = json.nb_loc;
      this.nbCounts = json.nb_rows;
      this.plotError = json.plot_error;
      this.overall = json.overall;
      this.summary = json.summary;
      this.timings = json.timings;


      if (! json.species){
        if (this.markersLayer){
          this.map.removeLayer(this.markersLayer);
        }
        if (this.squaresLayer){
          this.map.removeLayer(this.squaresLayer);
        }
        if (this.distributionLayer){
          this.map.removeLayer(this.distributionLayer);
        }
      }
      this.contributors = json.contributors;
      if (json.locations){
        this.locations = json.locations;
        if (this.showLocations){
          this.updateMap();
        }
      }
      this.distributionSquares = json.distribution;
      if (settings.showDistribution && this.distributionSquares){
        this.updateMapDistribution();
      }
      // if (json.squares) {
        this.squareSize = json.square_size;
        this.squares = json.squares;
      // }
      if (settings.showSquares) {
        this.updateMapSquare();
      }
      // this.centerMap();
      this.resizedListener();
    },
    updatePlot: function(){
      const plotId = this.getPlotId();
      if (this.plot) {
        // myd3(plotId, this.plot);
        const customOptions = {downloadName: this.store.plotSettings.species.id};
        const d3 = new MyD3(plotId, this.plot, customOptions, this.$tc);
        d3.visuals = this.getVisuals();

        const meaning = this.overall?.slope.meaning.toLowerCase();
        if (! this.hasEnoughData){
          d3.message = {
            text: this.$tc("trends.plotError.generic_error"),
          };
        }
        else if (meaning?.startsWith("uncertain")){
          d3.message = {
            text: this.$tc("trends.slope.Uncertain"),
            color: "orange"
          }
        }

        d3.draw();

      }
      else{
        //TODO: maybe implement for d3 (missing plot - for now at least clear it)
        const plotId = this.getPlotId();
        document.getElementById(plotId).innerHTML = '';
      }
    },
    getPlotId: function(){
      if (document.getElementById("divPlotTop") && document.getElementById("divPlotTop").clientHeight > 0){
        return "divPlotTop";
      }
      return "divPlot1";
    },
    handleError: function(error){
      this.fatalError = error;
    },
    raiseFatalError: function(name, message, status){
      this.fatalError = {
        name: name,
        status: status,
        message: message
      };
      this.working = true;
    },

    /**
     * called (by event) when settings have changed, and a request of the plot data is neccessary.
     */
    settingsChanged: function () {
      const settings = this.$refs.settings.settings;
      const speciesChanged = this.species !== settings.species;
      this.species = settings.species;
      this.speciesImages = this.species?.images || [];
      this.selectedRegion = settings.selectedRegion;
      this.map.updateUI();
      // if (speciesChanged) {
        this.updateBrowserUrl();
      // }
      this.requestData();
    },
    /**
     * called (by event) when settings were modified, but there is no intention
     * to automatically request the data (affects more settings).
     */
    settingsModify: function () {
      // this.map.updateUI();
    },
    requestData: function (){
      const data = new ApiRequestBuilder('options', this.$refs.settings.buildOptions()).build();
      ApiCom.post(this, data, this.handleResponse, this.handleError);
    },
    download: function(data){
      this.requestRawData(data);
    },
    requestRawData: function (options){
      const plotOptions = this.$refs.settings.buildOptions();
      const requestData = {
        options: options,
        plotOptions: plotOptions
      };
      axios.post('/dl/rawData/void', requestData, {
        responseType: 'blob', // important
      }).then((response) => {
        // generate a filename
        const filename = `${plotOptions.plotType}_${plotOptions.observationType}_${plotOptions.species}_${plotOptions.yearStart}-${plotOptions.yearEnd}.${options.type}`;
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },

    createMap: function (){
      if (this.map){
        return;
      }
      const map = new MyMap(this);
      map.create();
      map.callbackSelectRectangle = (bounds) => {
        this.$refs.settings.updateMapBounds(bounds)
      };
      this.map = map;
      map.map.on('moveend', this.moveMap)
    },

    centerMap: function (){
      this.map.centerMap();
    },

    getVisuals: function(){
      return new MyPlotVisuals();
    },
    // updateMap: function (){
    //   //  this is actually not used anymore
    //   //  it was built for location markers
    //   if (! this.map) return;
    //   if (this.markersLayer){
    //     this.map.removeLayer(this.markersLayer);
    //   }
    //   if (!this.showLocations) return;
    //   const allMarkers = [];
    //   const icon = Leaflet.icon({
    //     iconUrl: '/static/bats/misc/marker.svg',
    //     iconSize: [40, 40],
    //     iconAnchor: [20, 40]
    //   });
    //   const options = {icon: icon};
    //   for (let idx in this.locations){
    //     const loc = this.locations[idx];
    //     if (loc.lat !== undefined && loc.lon !== undefined) {
    //       // const marker = Leaflet.marker([loc.lat, loc.lon], options).addTo(map);
    //       const marker = Leaflet.marker([loc.lat, loc.lon], options);
    //       let popupHtml = '<h6>' + loc.name + '</h6><p class="text-muted">';
    //       if (loc.contributors){
    //         for (let pidx in loc.contributors){
    //           if (pidx > 0) popupHtml += ', ';
    //           popupHtml += loc.contributors[pidx].name;
    //         }
    //       }
    //       popupHtml += '</p>';
    //       marker.bindPopup(popupHtml);
    //       allMarkers.push(marker);
    //     }
    //   }
    //
    //   const markersLayer = new Leaflet.LayerGroup(allMarkers);
    //   markersLayer.addTo(this.map);
    //   this.markersLayer = markersLayer;
    //
    //   // const featureGroup = new Leaflet.FeatureGroup(allMarkers);
    //   // this.map.fitBounds(featureGroup.getBounds());
    // },
    updateMapSquare: function () {
      if (!this.map) return;
      this.map.createSitesLayer(this.squares);
    },
    updateMapDistribution: function(){
      if (!this.map) return;
      this.map.createDistributionLayer(this.distributionSquares);
    },

    // hsv2Rgb: function(h, s, v) {
    //   let r, g, b, i, f, p, q, t;
    //   if (arguments.length === 1) {
    //     s = h.s, v = h.v, h = h.h;
    //   }
    //   i = Math.floor(h * 6);
    //   f = h * 6 - i;
    //   p = v * (1 - s);
    //   q = v * (1 - f * s);
    //   t = v * (1 - (1 - f) * s);
    //   switch (i % 6) {
    //     case 0: r = v, g = t, b = p; break;
    //     case 1: r = q, g = v, b = p; break;
    //     case 2: r = p, g = v, b = t; break;
    //     case 3: r = p, g = q, b = v; break;
    //     case 4: r = t, g = p, b = v; break;
    //     case 5: r = v, g = p, b = q; break;
    //   }
    //   return {
    //     r: Math.round(r * 255),
    //     g: Math.round(g * 255),
    //     b: Math.round(b * 255)
    //   };
    // },
    updateBrowserUrl: function(){
      //  this is some ugly code to update the url (without using vue-router, maybe i should have used it?)
      // if (!this.speciesDict){
      //   //  this is the first call, so probably there's no need to update the url.
      //   return;
      // }

      //  first get the url and insert a trends path, if it's missing (when this is /)
      const url = new URL(window.location.href);
      const parts = url.pathname.split("/");
      if (parts[1].length === 0){
        parts.splice(1, 0, "trends");
      }

      //  now check, if the last part of the path needs to be replaced with the new species (true, when the current one is a species)
      const lastPart = parts[parts.length-1];
      let replaceLastPart = false;
      if (lastPart.length === 0)
        replaceLastPart = true;
      else if (this.speciesDict[lastPart]){
        replaceLastPart = true;
      }
      else {
        for (const [key, s] of Object.entries(this.speciesDict)) {
          if (s.latin.toLowerCase().replace(" ", "-") === lastPart) {
            replaceLastPart = true;
            break;
          }
        }
      }

      if (this.species) {
        //  replace or push the new species to the path
        if (replaceLastPart)
          parts[parts.length - 1] = this.species.latin.toLowerCase().replace(" ", "-");
        else
          parts.push(this.species.latin.toLowerCase().replace(" ", "-"));
      }

      //  finally update the path in browser
      url.pathname = parts.join("/");
      window.history.replaceState({}, '', url);
    },
  },
  computed:{
    // isMobile: function(){
    //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // },
    hasEnoughData: function(){
      return this.nbCounts >= 100;
    },
    //TODO: maybe reuse in PlotSettings (redundancy)
    allRegions: function(){
      return {id:-1, name: this.$t('plotOptions.allRegions')};
    },
    //TODO: maybe reuse in PlotSettings (redundancy)
    mapRegion: function(){
      return {id:-2, name: this.$t('plotOptions.mapSection')};
    },
    /**
     * return a HTML id for the leaflet map
     */
    idMap: function (){
      return this.prefix + 'map';
    }
  },
  // watch: {
  //   species: function (newVal, oldVal){
  //     alert('species changed'+newVal+' '+oldVal);
  //     const url = new URL(window.location.href);
  //     url.searchParams.set('species', newVal);
  //     window.history.replaceState({}, '', url);
  //   },
  // }
});
</script>
<style>
.my-scrollable{
  overflow-x: hidden;
  overflow-y: auto;
  /*height: 35vh;*/
}
.accordion-button-no-icon::after {
  display: none;
}
</style>